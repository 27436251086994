var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.estimate
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              !_vm.is_template
                ? _c("span", { staticClass: "ref" }, [
                    _vm._v(" / "),
                    _c("span", [_vm._v(" " + _vm._s(_vm.estimate.ref))]),
                  ])
                : [
                    _c("el-alert", {
                      staticClass: "template-banner",
                      attrs: {
                        "show-icon": "",
                        type: "info",
                        closable: false,
                        title:
                          "Changes to this template only apply to new estimates, and will not affect any existing estimates",
                      },
                    }),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "Delete this template?" },
                        on: { confirm: _vm.handleDeleteEstimate },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              plain: "",
                              type: "danger",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" Delete ")]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _c("hr"),
          _vm.is_estimate_accepted
            ? _c("el-alert", {
                staticClass: "status_banner",
                attrs: { type: "success", closable: false, title: "Accepted" },
              })
            : _vm._e(),
          _vm.is_estimate_declined
            ? _c("el-alert", {
                staticClass: "status_banner",
                attrs: { type: "error", closable: false, title: "Declined" },
              })
            : _vm._e(),
          !_vm.is_estimate_draft && !_vm.estimate.client
            ? _c("el-alert", {
                attrs: {
                  type: "error",
                  closable: false,
                  title:
                    "Estimate cannot be finalised until a client is selected.",
                  description: "Click 'Edit' to adjust estimate details.",
                },
              })
            : _vm._e(),
          _vm.editing
            ? _c("Estimate__form", {
                attrs: { estimate: _vm.estimate },
                on: { saved: _vm.closeEdit, cancel: _vm.closeEdit },
              })
            : _vm.estimate
            ? _c(
                "content-block",
                {
                  staticClass: "estimate-details",
                  attrs: {
                    title: _vm.estimate.title,
                    expanded: !!_vm.estimate.description,
                  },
                },
                [
                  _vm.estimate.description
                    ? _c("div", { key: _vm.increment }, [
                        _vm._v(" " + _vm._s(_vm.estimate.description) + " "),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "header-right",
                      attrs: { slot: "right" },
                      slot: "right",
                    },
                    [
                      _vm.estimate.payment_voided
                        ? _c(
                            "el-tag",
                            { attrs: { type: "danger", size: "mini" } },
                            [_vm._v(" Payment voided ")]
                          )
                        : _vm._e(),
                      _vm.estimate.change_request
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                type: "success",
                                plain: "",
                              },
                              on: { click: _vm.handleViewCR },
                            },
                            [
                              _c("i", { staticClass: "el-icon-link" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("fireRef2id")(
                                      _vm.estimate.change_request
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.is_estimate_draft
                        ? _c(
                            "div",
                            { staticClass: "subtask-toggle" },
                            [
                              _vm._v(" Show subtasks "),
                              _c("el-switch", {
                                model: {
                                  value: _vm.showSubtasks,
                                  callback: function ($$v) {
                                    _vm.showSubtasks = $$v
                                  },
                                  expression: "showSubtasks",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            disabled: _vm.editing || _vm.finalising,
                          },
                          on: { click: _vm.handleEdit },
                        },
                        [
                          _vm.is_estimate_locked
                            ? _c("span", [_vm._v("View Details")])
                            : _c("span", [_vm._v("Edit")]),
                        ]
                      ),
                      !_vm.is_estimate_draft && !_vm.no_exclusions
                        ? _c(
                            "el-select",
                            {
                              staticClass: "primary-selector",
                              attrs: { size: "small" },
                              model: {
                                value: _vm.copy_type,
                                callback: function ($$v) {
                                  _vm.copy_type = $$v
                                },
                                expression: "copy_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "full", label: "Full copy" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: "included",
                                  label: "Included only",
                                  disabled: _vm.all_groups_excluded,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: "excluded",
                                  label: "Excluded only",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            size: "small",
                            plain: "",
                            disabled: _vm.finalising,
                          },
                          on: { click: _vm.handleCloneEstimate },
                        },
                        [_vm._v(" Create a copy ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.editing
            ? _c(
                "draggable",
                _vm._b(
                  {
                    class: {
                      sortable: _vm.multiple_groups && !_vm.is_estimate_locked,
                    },
                    attrs: {
                      id: "estimate-groups",
                      list: _vm.sortingGroups,
                      handle: ".grip",
                      animation: 100,
                      disabled: !_vm.multiple_groups || _vm.is_estimate_locked,
                    },
                    on: { end: _vm.updateGroupOrder },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    _vm._l(_vm.sortingGroups, function (group) {
                      return _c("Estimate__group", {
                        key: group.id,
                        attrs: {
                          estimate: _vm.estimate,
                          group: group,
                          tasks: _vm.groupTasks(group.id),
                          show_subtasks:
                            !_vm.is_estimate_draft && _vm.showSubtasks,
                          can_delete: _vm.multiple_groups,
                          selected_task: _vm.selectedTask,
                        },
                        on: {
                          rename: _vm.handleRenameGroup,
                          delete: _vm.handleDeleteGroup,
                          exclude: _vm.handleExcludeGroup,
                          saveTask: _vm.handleSaveTask,
                          deleteTask: _vm.handleDeleteTask,
                          excludeTask: _vm.handleExcludeTask,
                          selectTask: _vm.handleSelectTask,
                          saveSubtask: _vm.handleSaveSubtask,
                          removeSubtask: _vm.handleRemoveSubtask,
                          deleteSubtask: _vm.handleDeleteSubtask,
                          excludeSubtask: _vm.handleExcludeSubtask,
                          newGroupAbove: function ($event) {
                            return _vm.handleClickOnNewGroupAbove(group)
                          },
                          newGroupBelow: function ($event) {
                            return _vm.handleClickOnNewGroupBelow(group)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.editing
            ? _c(
                "div",
                {
                  staticClass: "estimate-footer",
                  class: { tracking: _vm.show_tracking_bar },
                },
                [
                  _c(
                    "div",
                    { staticClass: "footer-total-section" },
                    [
                      _vm.is_estimate_pending
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "only-super-admin",
                                    rawName: "v-only-super-admin",
                                  },
                                ],
                                staticClass: "credit-toggle",
                                attrs: {
                                  disabled:
                                    _vm.credit.label || _vm.credit.value,
                                },
                              },
                              [
                                _c("el-switch", {
                                  model: {
                                    value: _vm.credit.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.credit, "enabled", $$v)
                                    },
                                    expression: "credit.enabled",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "credit-toggle-label" },
                                  [_vm._v("Include credit")]
                                ),
                                _vm.credit.enabled
                                  ? [
                                      _c("el-input", {
                                        staticClass: "credit-label",
                                        attrs: {
                                          size: "small",
                                          placeholder: "Credit description",
                                        },
                                        model: {
                                          value: _vm.credit.label,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.credit, "label", $$v)
                                          },
                                          expression: "credit.label",
                                        },
                                      }),
                                      _c(
                                        "money",
                                        _vm._b(
                                          {
                                            ref: "input_credit",
                                            staticClass:
                                              "credit-value el-input__inner",
                                            model: {
                                              value: _vm.credit.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.credit,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "credit.value",
                                            },
                                          },
                                          "money",
                                          _vm.money,
                                          false
                                        )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "footer-totals" },
                        [
                          _c("Estimate__totals", {
                            attrs: {
                              estimate: _vm.estimate,
                              tasks: _vm.sorted_tasks,
                              groups: _vm.groups,
                              credit: _vm.credit,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "white-margin" }),
                  this.is_estimate_locked && !_vm.documents.length
                    ? _c("div")
                    : _c(
                        "content-block",
                        { attrs: { title: "Documents" } },
                        [
                          _c(
                            "template",
                            { slot: "right" },
                            [
                              !this.is_estimate_locked
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: { click: _vm.handleNewDocument },
                                    },
                                    [_vm._v(" Add ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "document-list" },
                            [
                              _vm.documents.length
                                ? _vm._l(_vm.documents, function (doc) {
                                    return _c("document-block", {
                                      key: doc.id,
                                      attrs: { doc: doc, allow_delete: false },
                                    })
                                  })
                                : _c("el-alert", {
                                    attrs: {
                                      title:
                                        "No documents have been created yet.",
                                      closable: false,
                                    },
                                  }),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                  _c("div", { staticClass: "white-margin" }),
                  !_vm.is_template
                    ? _c("div", { staticClass: "button-bar" }, [
                        _c(
                          "div",
                          { staticClass: "buttons-left" },
                          [
                            _vm.is_estimate_pending
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "warning", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSetStatus(
                                          _vm.ESTIMATE_STATUS.DRAFT
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Return to Draft ")]
                                )
                              : _vm._e(),
                            _vm.is_estimate_declined
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "warning", plain: "" },
                                    on: { click: _vm.handleRestoreForReview },
                                  },
                                  [_vm._v(" Restore for Review ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "buttons-right" },
                          [
                            _vm.is_estimate_draft
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "info", plain: "" },
                                    on: { click: _vm.handleSubmitForReview },
                                  },
                                  [_vm._v(" Submit for Review ")]
                                )
                              : _vm._e(),
                            _vm.can_approve && !_vm.is_estimate_draft
                              ? [
                                  !_vm.estimate.payment_voided
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { plain: "" },
                                          on: {
                                            click: _vm.handleViewPaymentPlan,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.payment_plan_needs_planning
                                                    ? "Prepare payment plan"
                                                    : "View payment plan"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "top",
                                        content: _vm.mark_sent_tooltip,
                                        disabled: !_vm.mark_sent_tooltip,
                                      },
                                    },
                                    [
                                      _vm.is_estimate_pending
                                        ? _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName:
                                                    "v-loading.fullscreen.lock",
                                                  value: _vm.finalising,
                                                  expression: "finalising",
                                                  modifiers: {
                                                    fullscreen: true,
                                                    lock: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                "element-loading-text":
                                                  "Submitting quote to Xero...",
                                                disabled:
                                                  _vm.all_groups_excluded ||
                                                  !_vm.estimate.client ||
                                                  _vm.finalising ||
                                                  _vm.payment_plan_needs_planning,
                                                type: "info",
                                                plain: "",
                                              },
                                              on: {
                                                click:
                                                  _vm.handleFinaliseEstimate,
                                              },
                                            },
                                            [_vm._v(" Mark as Sent ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.is_estimate_sent
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              plain: "",
                                              disabled: _vm.finalising,
                                            },
                                            on: {
                                              click: _vm.handleDeclineEstimate,
                                            },
                                          },
                                          [_vm._v(" Decline ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "success",
                                              plain: "",
                                              disabled: _vm.finalising,
                                            },
                                            on: {
                                              click: _vm.handleAcceptEstimate,
                                            },
                                          },
                                          [_vm._v(" Accept ")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              : _vm.is_estimate_pending
                              ? _c(
                                  "el-button",
                                  {
                                    style: { pointerEvents: "none" },
                                    attrs: { type: "info", plain: "" },
                                  },
                                  [_vm._v(" Admin User required to submit ")]
                                )
                              : _vm._e(),
                            _vm.is_estimate_accepted
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "only-super-admin",
                                          rawName: "v-only-super-admin",
                                        },
                                      ],
                                      attrs: { plain: "" },
                                      on: {
                                        click: _vm.handleViewComponentsLinking,
                                      },
                                    },
                                    [
                                      _vm.estimate.ticketed
                                        ? _c("span", [_vm._v(" Components ")])
                                        : _c("span", [
                                            _vm._v(" Link to components "),
                                          ]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }