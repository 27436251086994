<template>
    <div
        class="document-row"
        :class="{
            invalid: !doc.isExternal && !doc.valid,
            editable: !doc.isExternal,
        }"
        @click="() => handleEditDocument(doc)"
    >
        <span class="title">
            {{ doc.name }}
        </span>
        <el-button
            v-if="doc.isExternal"
            icon="el-icon-link"
            size="mini"
            @click.stop="() => handleExternalLink(doc)"
        />
        <el-button
            v-else
            size="mini"
            icon="el-icon-download"
            :disabled="!doc.valid"
            @click.stop="() => (doc.valid ? generateDocumentPDF(doc) : 0)"
        />
        <el-popconfirm
            v-if="allow_delete"
            v-only-super-admin
            class="document-delete-confirm"
            title="Delete this document?"
            @confirm="() => handleDeleteDocument(doc)"
            @click.stop
        >
            <el-button
                slot="reference"
                type="danger"
                size="mini"
                plain
                icon="el-icon-delete"
                @click.stop
            />
        </el-popconfirm>
    </div>
</template>

<script>
import pdfMixin from '@/mixins/pdf.mixin';

export default {
    name: 'document-block',
    mixins: [pdfMixin],
    props: {
        doc: {
            type: Object,
            required: true,
        },
        allow_delete: {
            type: Boolean,
            default: true,
        },
    },
    computed: {},
    methods: {
        handleEditDocument(doc) {
            if (!doc.isExternal) {
                this.$router.push({
                    name: 'document_edit',
                    params: {
                        doc_id: doc.id,
                    },
                });
            }
        },
        handleDeleteDocument(doc) {
            this.$fire.doc(`documents/${doc.id}`).delete();
        },
        handleExternalLink(doc) {
            if (doc.url) {
                window.open(doc.url, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.document-row {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;
    align-items: center;

    &:last-of-type {
        margin-bottom: 0;
    }

    &.editable {
        cursor: pointer;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }
    &.invalid {
        background: rgba($orange-soft, 0.5);
        box-shadow: none;
        border: 1px solid $orange;
    }

    .title {
        text-align: left;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    }

    .el-button.ref {
        width: 120px;
        text-align: center;
        font-size: 11px;
    }

    span.title {
        margin-left: 12px;
        font-size: 13px;
        flex: 1;
    }

    .el-button {
        text-align: center;
        margin-left: 10px;
    }
}
</style>
