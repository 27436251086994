<template>
    <div
        class="task-footer"
        :class="{subset: subset, complete: is_estimate_locked}"
    >
        <el-row :gutter="5" type="flex" justify="end">
            <el-col
                :span="desc_header_width"
                class="total-label"
                :class="{'hidden-xs-only': !is_estimate_draft}"
            >
                Total
            </el-col>

            <el-col v-if="is_estimate_draft" :span="num_width" class="center">
                <span v-html="daysOrHours(total_min)" />
            </el-col>

            <el-col v-if="is_estimate_draft" :span="num_width" class="center">
                <span v-html="daysOrHours(total_max)" />
            </el-col>

            <!-- QTY / INITIAL -->
            <el-col :span="num_width" class="center">
                <span v-html="daysOrHours(total_qty)" />
            </el-col>

            <!-- QTY / FINAL -->
            <el-col v-if="!is_estimate_draft" :span="num_width" class="center">
                <span v-html="daysOrHours(total_final_qty)" />
            </el-col>

            <!-- HOURS -->
            <el-col
                v-if="estimate.unit === RATE_UNIT.DAY"
                :span="num_width"
                class="center"
            >
                <span
                    v-html="
                        $options.filters.hours2duration(
                            total_final_qty * estimate.hours_per_day
                        )
                    "
                />
            </el-col>

            <!-- COST -->
            <el-col v-if="!is_estimate_draft" :span="cost_width" class="right">
                <span class="total-value">{{ total_cost | currency }}</span>
            </el-col>
            <el-col v-else :span="2" class="right"></el-col>
        </el-row>

        <el-row
            v-if="!is_estimate_draft && group !== null"
            class="discount-row"
            :gutter="5"
            type="flex"
            justify="end"
        >
            <el-col v-if="show_link_to_component" :span="16">
                <span class="done-in-retainer">
                    <el-switch
                        v-model="link_to_component_enabled"
                        @change="(e) => handleUpdateLinkedComponent(e)"
                    />
                    Done in retainer
                </span>
                <el-select
                    v-if="
                        link_to_component_enabled &&
                        linkable_components.length > 1
                    "
                    :value="linked_retainer_component"
                    size="small"
                    @change="(c) => handleUpdateLinkedComponent(true, c)"
                >
                    <el-option
                        v-for="component of linkable_components"
                        :key="component.id"
                        :label="component.name"
                        :value="component"
                    />
                </el-select>
            </el-col>
            <el-col v-else-if="show_linked_label" :span="16">
                <span class="done-in-retainer">
                    Done in retainer
                </span>
            </el-col>
            <template v-if="show_discount">
                <el-col :span="3" class="discount-label">
                    <span>Discount %</span>
                </el-col>

                <el-col :span="num_width" class="center">
                    <el-input-number
                        ref="input_discount"
                        v-model="discount"
                        class="input-value"
                        :controls="false"
                        size="small"
                        placeholder="0"
                        :min="0"
                        :max="100"
                        :disabled="is_estimate_locked || show_linked_label"
                        @change="handleUpdateDiscount"
                        @focus="selectInput"
                    />
                </el-col>
            </template>

            <el-col :span="cost_width" class="right group-total">
                <span class="total-value">
                    {{ total_cost_discounted | currency }}
                </span>
            </el-col>
        </el-row>

        <div
            v-only-super-admin
            v-if="credit?.enabled && !is_estimate_draft"
            class="credit-section"
        >
            <el-row class="credit-row" :gutter="5">
                <el-col :span="21">
                    <span>
                        {{ credit.label || 'Credit' }}
                    </span>
                </el-col>
                <el-col :span="3">
                    <span class="total-value">
                        {{ -credit.value | currency }}
                    </span>
                </el-col>
            </el-row>
            <el-row class="credit-row" :gutter="5">
                <el-col :span="21">
                    <span>Total after credit</span>
                </el-col>
                <el-col :span="3">
                    <span class="total-value">
                        {{ total_after_credit | currency }}
                    </span>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import estimateMixin from '@/mixins/estimate.mixin';
import {RATE_UNIT} from '@/enums';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import debounce from 'lodash.debounce';

export default {
    name: 'estimate-totals',
    mixins: [estimateMixin, estimateTaskMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        group: {
            type: Object,
            default: null,
        },
        groups: {
            type: Array,
            default: null,
        },
        subset: {
            type: Boolean,
            default: false,
        },
        credit: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            link_to_component_enabled: false,
            discount: 0,
        };
    },
    computed: {
        valid_tasks() {
            if (!this.is_estimate_draft && this.group?.excluded) return [];
            return this.tasks.filter((item) => {
                if (this.groups) {
                    // TODO: Can we just use this.group? and drop groups prop?
                    const group = this.groups.find(
                        (g) =>
                            g.id ===
                            this.$options.filters.fireRef2id(item.group)
                    );
                    if (!this.is_estimate_draft && group?.excluded)
                        return false;
                }
                return item.is_invalid === false && !item.excluded;
            });
        },
        total_min() {
            return +parseFloat(
                this.valid_tasks.reduce((total_min, task) => {
                    if (task.subtasks) {
                        return (
                            total_min +
                            task.subtasks.reduce((taskMin, subtask) => {
                                if (subtask.min) return taskMin + subtask.min;
                                else return taskMin;
                            }, 0)
                        );
                    } else return total_min;
                }, 0)
            ).toFixed(2);
        },
        total_max() {
            return +parseFloat(
                this.valid_tasks.reduce((total_max, task) => {
                    if (task.subtasks) {
                        return (
                            total_max +
                            task.subtasks.reduce((taskMax, subtask) => {
                                if (subtask.max) return taskMax + subtask.max;
                                else return taskMax;
                            }, 0)
                        );
                    } else return total_max;
                }, 0)
            ).toFixed(2);
        },
        total_qty() {
            return +parseFloat(
                this.valid_tasks.reduce((acc, cur) => {
                    if (cur.qty && this.groupForTask(cur) !== false) {
                        return acc + cur.qty;
                    } else return acc;
                }, 0)
            ).toFixed(2);
        },
        total_final_qty() {
            return +parseFloat(
                this.valid_tasks.reduce((acc, cur) => {
                    if (this.groupForTask(cur) !== false) {
                        return (
                            acc +
                            estimateTaskMixin.methods.task_qty(
                                cur,
                                this.is_estimate_draft
                            )
                        );
                    }
                    return acc;
                }, 0)
            ).toFixed(2);
        },
        total_cost() {
            return +parseFloat(
                this.valid_tasks.reduce((acc, cur) => {
                    if (cur.cost) {
                        if (this.groups) {
                            // for estimate total: return task cost discounted by its group discount
                            return acc + this.groupTaskCost(cur);
                        }
                        return acc + cur.cost;
                    } else return acc;
                }, 0)
            ).toFixed(2);
        },
        total_cost_discounted() {
            return this.total_cost * ((100 - (this.discount || 0)) / 100);
        },
        linkable_components() {
            // At the moment we only allow linking to active - recurring components
            return this.$store.getters
                .componentsForProjectWithId(
                    this.$options.filters.fireRef2id(this.estimate.project)
                )
                .filter((comp) => comp.recurring && comp.status === 'active');
        },
        show_link_to_component() {
            return !(
                this.is_estimate_completed ||
                this.linkable_components.length === 0
            );
        },
        show_linked_label() {
            return this.link_to_component_enabled && this.is_estimate_completed;
        },
        show_discount() {
            if (this.is_estimate_locked && this.discount === 0) return false;
            return true;
        },
        linked_retainer_component() {
            if (this.group?.ref_links?.['retainer_component']) {
                return this.$store.getters.componentWithId(
                    this.group.ref_links['retainer_component']
                );
            }
            return null;
        },
        total_after_credit() {
            return (
                this.getTotalForGroups(this.groups, this.tasks) -
                    this.credit?.value ?? 0
            );
        },
    },
    watch: {
        'estimate.status': {
            handler() {
                this.setReadOnlyInputs();
            },
        },
    },
    mounted() {
        this.setReadOnlyInputs();

        //Initialise data based on provided group
        if (this.group?.ref_links?.['retainer_component']) {
            this.link_to_component_enabled = true;
        }
        if (this.group?.discount) {
            this.discount = this.group?.discount ?? 0;
        }
    },
    methods: {
        daysOrHours(value) {
            switch (this.estimate.unit) {
                case RATE_UNIT.DAY:
                    return `${value} <small>${this.$options.filters.pluralize(
                        'day',
                        value
                    )}</small>`;
                case RATE_UNIT.HOUR:
                default:
                    return this.$options.filters.hours2duration(value);
            }
        },
        handleUpdateDiscount() {
            if (this.group) {
                this.$store.dispatch('updateEstimateGroupDiscount', {
                    group_id: this.group.id,
                    discount: this.discount ?? 0,
                });
            }
        },
        handleUpdateLinkedComponent(
            enabled,
            component = this.linkable_components[0]
        ) {
            if (this.group) {
                let ref_link = {};

                if (enabled) {
                    // If the linked component is recurring - set discount to 100%
                    this.discount = component.recurring ? 100 : 0;
                    // Link to the specified component
                    ref_link = {
                        key: 'retainer_component',
                        ref: this.$fire.doc(`components/${component.id}`),
                    };
                } else {
                    this.discount = 0;
                    // Remove linking
                    ref_link = {
                        key: 'retainer_component',
                        ref: null,
                    };
                }

                this.$store.dispatch('updateEstimateGroupRefLink', {
                    group_id: this.group.id,
                    ref_link,
                    discount: this.discount,
                });
            }
        },
        groupForTask(task) {
            if (this.groups) {
                // TODO: cant we just use this.group prop?
                const group = this.groups.find(
                    (g) => g.id === this.$options.filters.fireRef2id(task.group)
                );
                if (!group) return false;
                return group;
            }
            return null;
        },
        groupTaskCost(task) {
            if (this.groups) {
                // TODO: cant we just use this.group prop?
                // only return task cost if it belongs to an included group, and factor in the discount
                const group = this.groups.find(
                    (g) => g.id === this.$options.filters.fireRef2id(task.group)
                );
                if (group) {
                    return task.cost * ((100 - (group.discount || 0)) / 100);
                } else {
                    return 0;
                }
            }
            return task.cost;
        },
        setReadOnlyInputs() {
            if (this.$refs.input_discount && this.group) {
                this.$refs.input_discount.$el.querySelector(
                    'input'
                ).readOnly = this.is_estimate_locked;
            }
        },
        selectInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 50);
        },
    },
};
</script>

<style lang="scss" scoped>
.el-col.align-left {
    text-align: left !important;

    span {
        padding: 0 15px;
        font-weight: bold;

        small {
            font-weight: normal;
        }
    }
}

.task-footer {
    font-size: 11px;
    font-weight: bold;
    margin: 0;
    flex: 1;

    .center {
        text-align: center;
    }
    .right {
        text-align: right;
    }
    .total-label {
        text-align: right;
    }
    .total-value {
        // additional padding to account for offset due to exclude toggle
        padding-right: 48px;
    }
    &.complete {
        .total-value {
            padding-right: 18px;
        }
    }

    .discount-row {
        margin-top: 20px;
        border-top: 1px solid $transparent-grey;
        padding-top: 10px;
        display: flex;
        align-items: center;

        .done-in-retainer {
            font-weight: normal;
            display: flex;
            align-items: center;
            height: 32px;
            > * {
                margin-right: 10px;
            }
        }
        .discount-label {
            text-align: right;
            margin-right: 10px;
        }
    }

    .credit-section {
        padding-top: 10px;

        .credit-row {
            margin-top: 10px;
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: bold;
        }
    }

    ::v-deep .input-value {
        width: 100%;

        &.read-only,
        &.read-only input,
        input:read-only {
            background: transparent;
            color: #a0a0a0;
            pointer-events: none;
            border: none;
        }

        input {
            padding-left: 5px !important;
            padding-right: 5px !important;
            text-align: center;
        }
    }

    &.subset {
        color: $blue;
    }
}
</style>
