var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "group-container",
      class: { excluded: _vm.render_excluded, expanded: _vm.is_expanded },
    },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c("div", { staticClass: "grip" }, [_vm._v("⣿")]),
          _c("svgicon", {
            staticClass: "jira-icon",
            attrs: { name: "jira-epic" },
          }),
          _c(
            "el-input",
            {
              staticClass: "title",
              class: {
                empty: !_vm.title,
                dirty: _vm.is_dirty,
                invalid: _vm.is_invalid,
                draft: _vm.is_estimate_draft,
              },
              attrs: {
                readonly: _vm.is_estimate_locked,
                size: "small",
                placeholder: "Group title",
              },
              on: { blur: _vm.handleBlur },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onSubmitDescription.apply(null, arguments)
                },
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            },
            [
              _c(
                "el-tooltip",
                { attrs: { slot: "suffix", placement: "top" }, slot: "suffix" },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c("strong", [_vm._v("Estimate groups")]),
                    _vm._v(" are top-level groupings of broad tasks."),
                    _c("br"),
                    _c("br"),
                    _vm._v(" Each group becomes a "),
                    _c("strong", [_vm._v("Line Item")]),
                    _vm._v(" in the quote,"),
                    _c("br"),
                    _vm._v(" and a "),
                    _c("strong", [_vm._v("Jira epic")]),
                    _vm._v(" when the estimate is accepted. "),
                  ]),
                  _c("span", { staticClass: "info-icon" }, [_vm._v("?")]),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "collapse", on: { click: _vm.toggleExpanded } },
            [
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "options-col" },
            [
              _vm.is_estimate_draft
                ? _c("el-button", {
                    attrs: { size: "small", icon: "el-icon-plus" },
                    on: {
                      click: function ($event) {
                        return _vm.handleSaveTask(null)
                      },
                    },
                  })
                : _vm._e(),
              _vm.is_estimate_draft
                ? [
                    !_vm.estimate.change_request
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              trigger: "click",
                              "popper-class": "options-popover",
                            },
                            model: {
                              value: _vm.show_options,
                              callback: function ($$v) {
                                _vm.show_options = $$v
                              },
                              expression: "show_options",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                plain: "",
                                size: "small",
                                icon: "el-icon-arrow-down",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              slot: "reference",
                            }),
                            _c(
                              "ul",
                              [
                                _c("li", { staticClass: "header" }, [
                                  _vm._v(" Add group: "),
                                ]),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleNewGroup(
                                          "newGroupAbove"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Above ")]
                                ),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleNewGroup(
                                          "newGroupBelow"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Below ")]
                                ),
                                _vm.can_delete && !_vm.has_nontrivial_tasks
                                  ? [
                                      _c("hr"),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "destructive",
                                          on: { click: _vm.handleDelete },
                                        },
                                        [_vm._v(" Delete ")]
                                      ),
                                    ]
                                  : _vm.can_delete && _vm.has_nontrivial_tasks
                                  ? [
                                      _c("hr"),
                                      _c(
                                        "el-popconfirm",
                                        {
                                          staticClass: "delete-confirm",
                                          attrs: {
                                            placement: "bottom-end",
                                            title: _vm.confirm_delete_text,
                                          },
                                          on: { confirm: _vm.handleDelete },
                                        },
                                        [
                                          _c(
                                            "li",
                                            {
                                              staticClass: "destructive",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [_vm._v(" Delete ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : !_vm.is_estimate_locked
                ? _c("el-button", {
                    class: { selected: _vm.is_excluded },
                    attrs: {
                      type: "warning",
                      plain: "",
                      size: "small",
                      icon: "el-icon-minus",
                    },
                    on: { click: _vm.handleExclude },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_expanded,
              expression: "is_expanded",
            },
          ],
          staticClass: "tasks-container",
        },
        [
          _vm.tasks.length
            ? _c(
                "div",
                { staticClass: "task-header" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 5 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "description",
                          attrs: { span: _vm.desc_header_width },
                        },
                        [_vm._v(" Description ")]
                      ),
                      _vm.is_estimate_draft
                        ? _c(
                            "el-col",
                            {
                              staticClass: "center",
                              attrs: { span: _vm.num_width },
                            },
                            [
                              _vm._v(
                                " Min (" + _vm._s(_vm.estimate.unit) + "s) "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.is_estimate_draft
                        ? _c(
                            "el-col",
                            {
                              staticClass: "center",
                              attrs: { span: _vm.num_width },
                            },
                            [
                              _vm._v(
                                " Max (" + _vm._s(_vm.estimate.unit) + "s) "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        {
                          staticClass: "center",
                          attrs: { span: _vm.num_width },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.is_estimate_draft ? "Initial" : "Qty"
                              ) +
                              " (" +
                              _vm._s(_vm.estimate.unit) +
                              "s) "
                          ),
                        ]
                      ),
                      !_vm.is_estimate_draft
                        ? _c(
                            "el-col",
                            {
                              staticClass: "center",
                              attrs: { span: _vm.num_width },
                            },
                            [
                              _vm._v(
                                " Final (" + _vm._s(_vm.estimate.unit) + "s) "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.estimate.unit === _vm.RATE_UNIT.DAY
                        ? _c(
                            "el-col",
                            {
                              staticClass: "center",
                              attrs: { span: _vm.num_width },
                            },
                            [_vm._v(" Hours ")]
                          )
                        : _vm._e(),
                      !_vm.is_estimate_draft
                        ? _c(
                            "el-col",
                            {
                              staticClass: "center cost",
                              attrs: { span: _vm.cost_width },
                            },
                            [_vm._v(" Cost ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "tasks-dropzone",
                class: {
                  itemSortable: !_vm.is_estimate_locked,
                  empty: !_vm.sortedTasks.length,
                },
                attrs: {
                  list: _vm.sortedTasks,
                  handle: ".taskGrip",
                  disabled: _vm.is_estimate_locked,
                  group: "tasks",
                  animation: 100,
                },
                on: {
                  end: _vm.updateTaskOrder,
                  add: _vm.handleChangeTaskGroup,
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            _vm._l(_vm.sortedTasks, function (task) {
              return _c("Estimate__task", {
                key: task.id,
                ref: `task_${task.id}`,
                refInFor: true,
                attrs: {
                  estimate: _vm.estimate,
                  group: _vm.group,
                  task: task,
                  show_subtasks: _vm.show_subtasks,
                  selected: _vm.selected_task === task.id,
                  can_delete: !_vm.is_estimate_locked,
                  readonly: _vm.is_estimate_locked,
                },
                on: {
                  save: _vm.handleSaveTask,
                  delete: _vm.handleDeleteTask,
                  exclude: _vm.handleExcludeTask,
                  saveSubtask: _vm.handleSaveSubtask,
                  removeSubtask: _vm.handleRemoveSubtask,
                  deleteSubtask: _vm.handleDeleteSubtask,
                  excludeSubtask: _vm.handleExcludeSubtask,
                  select: _vm.handleSelectTask,
                },
              })
            }),
            1
          ),
          _vm.tasks.length
            ? _c("Estimate__totals", {
                attrs: {
                  estimate: _vm.estimate,
                  group: _vm.group,
                  tasks: _vm.tasks,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }