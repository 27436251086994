<template>
    <div
        class="task-container"
        :class="{
            invalid: !render_excluded && is_invalid,
            'is-draft': is_estimate_draft,
            excluded: render_excluded,
            selected: selected && !show_subtasks,
            selectable: !is_estimate_draft && !show_subtasks,
        }"
        @click="$emit('select', task.id)"
    >
        <div class="task-row">
            <el-form ref="task_form" :model="form" @submit.prevent.native>
                <el-row :gutter="5">
                    <el-col :span="desc_width">
                        <div class="row description">
                            <div class="taskGrip">⣿</div>
                            <svgicon
                                :name="ISSUE_ICONS[jira_category]"
                                :class="ISSUE_ICONS[jira_category]"
                                class="jira-icon"
                            />
                            <el-input
                                ref="description"
                                v-model="form.description"
                                class="input-description"
                                :class="{
                                    empty: isEmpty(form.description),
                                }"
                                size="small"
                                placeholder="task description"
                                :readonly="readonly"
                                @keyup.enter.native="onSubmitDescription"
                                @click.native.stop
                            >
                                <el-tooltip slot="suffix" placement="top">
                                    <div slot="content">
                                        <strong>Tasks</strong> define the main
                                        blocks of work within a group.<br /><br />
                                        Tasks become the description of the
                                        <strong>Line Item</strong> in the
                                        quote,<br />
                                        and <strong>Jira issues</strong> when
                                        the estimate is accepted.
                                    </div>
                                    <span class="info-icon">?</span>
                                </el-tooltip>
                            </el-input>
                        </div>
                    </el-col>

                    <!-- QTY / INITIAL -->
                    <el-col :span="num_width">
                        <div class="row">
                            <el-input-number
                                v-if="!has_subtasks && is_estimate_draft"
                                ref="input_qty"
                                v-model="form.qty"
                                class="input-value"
                                :controls="false"
                                size="small"
                                placeholder="0"
                                :min="0"
                                @change="handleBlurQty"
                                @focus="selectInput"
                            />
                            <span
                                v-else
                                class="el-input__inner input-value read-only"
                                :class="{bold: is_estimate_draft}"
                            >
                                {{ formattedQty }}
                            </span>
                        </div>
                    </el-col>

                    <!-- QTY / FINAL -->
                    <el-col v-if="!is_estimate_draft" :span="num_width">
                        <div class="row">
                            <el-input-number
                                v-if="
                                    !has_subtasks &&
                                    is_estimate_pending &&
                                    (show_subtasks || selected)
                                "
                                ref="input_override_qty"
                                v-model="override.qty"
                                class="input-value override-qty"
                                :precision="2"
                                :controls="false"
                                size="small"
                                :min="0"
                                :placeholder="`${formattedQty}`"
                                @focus="selectInput"
                                @click.native.stop
                            />
                            <span
                                v-else
                                class="el-input__inner input-value read-only"
                            >
                                {{ formattedOverrideQty }}
                            </span>
                        </div>
                    </el-col>

                    <!-- HOURS -->
                    <el-col
                        v-if="estimate.unit === RATE_UNIT.DAY"
                        :span="num_width"
                    >
                        <div class="row">
                            <el-input
                                ref="input_hours"
                                :value="$options.filters.hours2duration(hours)"
                                class="input-value read-only"
                                :class="{bold: is_estimate_draft}"
                                size="small"
                            />
                        </div>
                    </el-col>

                    <!-- COST -->
                    <el-col v-if="!is_estimate_draft" :span="cost_width">
                        <div class="row">
                            <money
                                ref="input_cost"
                                :value="task.cost"
                                v-bind="money"
                                class="el-input__inner bold cost read-only right"
                            />

                            <div class="options-col">
                                <el-button
                                    v-if="!is_estimate_locked"
                                    type="warning"
                                    plain
                                    :class="{selected: is_excluded}"
                                    size="small"
                                    icon="el-icon-minus"
                                    @click="handleExclude"
                                />
                            </div>
                        </div>
                    </el-col>

                    <!-- ACTIONS -->
                    <el-col
                        v-if="is_estimate_draft && !readonly"
                        :span="option_width"
                    >
                        <div class="options-col">
                            <el-button
                                size="small"
                                icon="el-icon-plus"
                                @click="handleAddSubtask"
                            />

                            <el-button
                                v-if="is_dirty"
                                :style="{pointerEvents: 'none'}"
                                size="small"
                                icon="el-icon-loading"
                            />
                            <el-popconfirm
                                v-else-if="has_nontrivial_subtasks"
                                placement="top"
                                :title="confirm_delete_text"
                                @confirm="handleDelete"
                            >
                                <el-button
                                    slot="reference"
                                    type="danger"
                                    plain
                                    size="small"
                                    icon="el-icon-close"
                                    @click.stop
                                />
                            </el-popconfirm>
                            <el-button
                                v-else
                                type="danger"
                                plain
                                size="small"
                                icon="el-icon-close"
                                :disabled="!can_delete"
                                @click="handleDelete"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div
            v-if="
                is_estimate_draft || show_subtasks || (selected && has_subtasks)
            "
            class="subtasks-container"
            @click.stop
        >
            <draggable
                :list="sortedSubtasks"
                handle=".subtaskGrip"
                v-bind="dragOptions"
                :disabled="is_estimate_locked || readonly || show_subtasks"
                class="subtasks-dropzone"
                :class="{
                    itemSortable: !is_estimate_locked && !readonly,
                    empty: !sortedSubtasks.length,
                }"
                group="task"
                :animation="100"
                @end="updateSubtaskOrder"
                @add="handleChangeSubtaskParent"
            >
                <Estimate__subtask
                    v-for="subtask in sortedSubtasks"
                    :ref="`subtask_${subtask.id}`"
                    :key="subtask.id"
                    :estimate="estimate"
                    :group="group"
                    :task="task"
                    :subtask="subtask"
                    :can_delete="is_estimate_draft"
                    :readonly="readonly"
                    :adjustment="finalAdjustment"
                    @save="handleSaveSubtask"
                    @delete="handleDeleteSubtask"
                    @exclude="handleExcludeSubtask"
                    @nextSubtask="handleNextSubtask"
                />
            </draggable>
        </div>
    </div>
</template>

<script>
import {VMoney} from 'v-money';

import Estimate__subtask from '@/pages/estimates/components/Estimate_subtask';

import estimateMixin from '@/mixins/estimate.mixin';
import estimateTaskMixin from '@/mixins/estimate.task.mixin';
import draggable from 'vuedraggable';
import {ESTIMATE_TYPES, ISSUE_CATEGORIES, ISSUE_ICONS} from '@/enums';
import debouncePromise from '@/utils/debouncePromise';

export default {
    name: 'estimate-task',
    directives: {money: VMoney},
    components: {
        Estimate__subtask,
        draggable,
    },
    mixins: [estimateMixin, estimateTaskMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
        group: {
            type: Object,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
        can_delete: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        show_subtasks: {
            // force display of subtasks on pending estimates
            type: Boolean,
            default: false,
        },
        selected: {
            // force display of subtasks for this task ID on pending estimates
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                description: '',
            },
            override: {
                qty: undefined,
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
            },
            sortedSubtasks: [],
            dragOptions: {
                animation: 200,
                ghostClass: 'ghost',
                dragClass: 'dragging',
            },
            is_dirty: false,
        };
    },
    computed: {
        ISSUE_ICONS() {
            return ISSUE_ICONS;
        },
        subtasks() {
            return this.task.subtasks ?? [];
        },
        has_subtasks() {
            return this.subtasks.length > 0;
        },
        has_nontrivial_subtasks() {
            return this.subtasks.some((subtask) => {
                return (
                    subtask.description ||
                    subtask.min ||
                    subtask.max ||
                    subtask.qty ||
                    subtask.notes
                );
            });
        },
        confirm_delete_text() {
            return `Deleting this task will also delete its ${this.subtasks.length} subtasks. Are you sure?`;
        },
        is_excluded() {
            return this.task.excluded;
        },
        jira_category() {
            if (this.has_subtasks) {
                switch (this.estimate.type) {
                    case ESTIMATE_TYPES.CREATE:
                        return ISSUE_CATEGORIES.FEATURE;
                    case ESTIMATE_TYPES.EXPLORE:
                        return ISSUE_CATEGORIES.ACTIVITY;
                    default:
                        return ISSUE_CATEGORIES.DEFAULT;
                }
            } else return ISSUE_CATEGORIES.ADMIN;
        },
        render_excluded() {
            return (
                !this.is_estimate_draft &&
                (this.is_excluded ||
                    this.group.excluded ||
                    this.isTaskExcluded(this.task, this.subtasks))
            );
        },
        has_dirty_override() {
            if (
                this.task.override?.qty === null &&
                this.override.qty === undefined
            )
                return false;
            if (!this.task.override?.qty && this.override.qty) return true;
            return this.task.override?.qty !== this.override.qty;
        },
        is_invalid() {
            if (!this.has_valid_description) return true; //Description is not valid
            return false;
        },
        has_valid_description() {
            return !!this.form.description;
        },
        hours() {
            return estimateTaskMixin.methods.task_hours(
                this.task,
                this.is_estimate_draft
            );
        },
        formattedQty() {
            return Number(this.task.qty.toFixed(2));
        },
        formattedOverrideQty() {
            return Number(
                (this.task.override?.qty ?? this.task.qty).toFixed(2)
            );
        },
        finalAdjustment() {
            if (this.task.override?.qty) {
                return this.task.override.qty / this.task.qty;
            }
            return null;
        },
    },
    watch: {
        task: {
            handler(val) {
                if (val.id) this.form.id = val.id;
                this.form.description = val.description;
                this.form.qty = val.qty;
                this.override.qty = val.override?.qty ?? undefined;
                this.validateTask();
            },
            immediate: true,
        },
        form: {
            handler() {
                this.dirtyCheck();
                this.saveItem();
            },
            deep: true,
        },
        jira_category: {
            handler(val) {
                if (val !== this.task.jira_category) {
                    this.saveItem();
                }
            },
            immediate: true,
        },
        subtasks: {
            handler(val) {
                this.sortedSubtasks = val
                    .slice(0)
                    .sort((a, b) => a.sort - b.sort);
            },
            immediate: true,
        },
        readonly(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setReadOnlyInputs();
            }
        },
        'override.qty'() {
            this.dirtyCheck();
            this.saveItem();
        },
        'task.override': {
            handler(newVal, oldVal) {
                // Override values:
                if (newVal?.qty !== oldVal?.qty) {
                    this.override.qty = newVal.qty ?? undefined;
                }
            },
            immediate: true,
        },
        selected(val) {
            if (val) {
                // wait for render then attempt to set focus on input field if available
                setTimeout(() => {
                    const qtyInput = this.$refs.input_override_qty;
                    if (qtyInput) {
                        qtyInput.focus();
                    }
                }, 100);
            }
        },
    },
    mounted() {
        this.setReadOnlyInputs();
    },
    created() {
        this.saveItem = debouncePromise(this.saveItem, 500);
    },
    methods: {
        dirtyCheck() {
            if (
                this.task.description !== this.form.description ||
                this.task.jira_category !== this.jira_category ||
                this.has_dirty_override
            ) {
                this.is_dirty = true;
            }
        },
        validateTask() {
            if (this.task.cost !== this.task_cost(this.task))
                this.saveItem(true);
        },
        async saveItem(force = false) {
            if (this.is_dirty || force) {
                this.$emit('save', {
                    ...this.task,
                    jira_category: this.jira_category,
                    ...this.form,
                    override: {
                        qty: !this.has_subtasks
                            ? this.override.qty ?? null
                            : this.task.override.qty ?? null,
                    },
                    is_invalid: this.is_invalid,
                });
                this.is_dirty = false;
                return new Promise((res) => setTimeout(res, 200));
            }
        },
        handleBlurQty() {
            // if the initial estimate qty is changed, we are in draft; reset the qty override
            this.override.qty = undefined;
            this.saveItem(true);
        },
        handleDelete() {
            if (!this.is_estimate_locked) {
                this.$emit('delete', this.task.id);
            }
        },
        isEmpty(value) {
            return !value && value !== 0;
        },
        setReadOnlyInputs() {
            if (!this.is_estimate_draft) {
                this.$refs.input_cost.$el.readOnly = true;
            }
        },
        focus() {
            this.$refs.description.focus();
        },
        async handleSaveSubtask(subtask) {
            let saveSubtask = {
                jira_category:
                    this.estimate.type === ESTIMATE_TYPES.EXPLORE
                        ? ISSUE_CATEGORIES.ACTION
                        : ISSUE_CATEGORIES.DEFAULT,
                ...subtask,
                id: subtask.id,
                task: this.$fire.doc(`estimate_tasks/${this.task.id}`),
            };
            // remove temporary working values
            delete saveSubtask.movingTo;
            this.$emit('saveSubtask', saveSubtask);
            return new Promise((res) => setTimeout(res, 200));
        },
        handleDeleteSubtask(subtaskId) {
            this.$emit('deleteSubtask', subtaskId);
        },
        handleExcludeSubtask(subtaskId) {
            this.$emit('excludeSubtask', subtaskId);
        },
        updateSubtaskOrder(event) {
            const item = event.item._underlying_vm_;

            // if item has been dragged to a different task,
            // remove from this task. (No further sorting is required)
            if (item.movingTo && item.movingTo !== this.task.id) {
                this.$emit('removeSubtask', item.id);
                return;
            }

            let before = null;
            let after = null;
            if (event.newIndex > 0) {
                before = this.sortedSubtasks[event.newIndex - 1];
            }
            if (event.newIndex < this.sortedSubtasks.length - 1) {
                after = this.sortedSubtasks[event.newIndex + 1];
            }
            if (!before && !after) return; // only item in list

            let sort = 0;
            if (!before) {
                // first, set sort index 1 before following item
                sort = after.sort - 1;
            } else if (!after) {
                // last, set sort index 1 after preceding item
                sort = before.sort + 1;
            } else {
                // set sort index halfway between neighbours
                sort = (before.sort + after.sort) / 2;
            }
            this.$store.dispatch('updateEstimateSubtaskOrder', {
                subtask_id: item.id,
                sort,
            });
        },
        handleChangeSubtaskParent(event) {
            const subtask = event.item._underlying_vm_;
            subtask.movingTo = this.task.id;
            this.handleSaveSubtask(subtask).then(() =>
                // move event also contains sorting data
                this.updateSubtaskOrder(event)
            );
        },
        focusSubtask(subtaskId, field) {
            // set focus on a subtask
            const subtaskRef = this.$refs[`subtask_${subtaskId}`];
            const subtaskEl = subtaskRef ? subtaskRef[0] ?? null : null;
            if (subtaskEl) {
                subtaskEl.focus(field);
            }
        },
        onSubmitDescription() {
            if (this.is_dirty) {
                this.saveItem().then(() => {
                    this.handleNextSubtask(
                        this.sortedSubtasks[0]?.id,
                        'description'
                    );
                });
            } else {
                this.handleNextSubtask(
                    this.sortedSubtasks[0]?.id,
                    'description'
                );
            }
        },
        handleNextSubtask(subtaskId, field) {
            let foundCurrent = false;
            let nextSubtask = null;
            for (const subtask of this.sortedSubtasks) {
                // first, find the current subtask
                if (subtask.id === subtaskId) {
                    foundCurrent = true;
                } else if (foundCurrent) {
                    // once found, look for the next subtask that doesn't have a truthy value for the corresponding field
                    if (!subtask[field]) {
                        nextSubtask = subtask;
                        break;
                    }
                }
            }
            if (nextSubtask) {
                this.focusSubtask(nextSubtask.id, field);
            } else if (this.is_estimate_draft) {
                // no next subtask found, create a new one
                this.handleAddSubtask().then(() => {
                    // After subtask is added, give it focus
                    const lastSubtask = this.sortedSubtasks[
                        this.sortedSubtasks.length - 1
                    ];
                    this.focusSubtask(lastSubtask.id, field);
                });
            }
        },
        async handleAddSubtask() {
            // if this is the first subtask created, move the task qty into the subtask
            const qty = this.has_subtasks ? 0 : this.form.qty ?? 0;
            return this.handleSaveSubtask({
                estimate: this.task.estimate,
                group: this.task.group,
                task: `estimate_tasks/${this.task.id}`,
                qty,
            });
        },
        selectInput(event) {
            setTimeout(() => {
                event.target.select();
            }, 50);
        },
        handleExclude() {
            if (this.is_estimate_pending) {
                this.$emit('exclude', this.task.id);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.task-container {
    border-radius: 6px;
    transition: background-color 0.1s linear;
    margin-bottom: 10px;
    padding: 2px;

    &.selectable {
        cursor: pointer;
    }

    background-color: $border-grey-light;

    &:hover,
    &.selected {
        background-color: $border-grey-light;
    }

    &.invalid {
        background-color: $red-background;

        ::v-deep input {
            color: $red;
        }
    }

    ::v-deep .el-input.input-description input {
        border: none;
    }

    &:not(.invalid) .empty ::v-deep input {
        border-color: $orange-soft;
    }

    .task-row {
        padding: 2px;

        .info-icon {
            width: 14px;
            height: 14px;
            font-size: 12px;
            font-weight: normal;
            color: black;
            border: 1px solid black;
            border-radius: 50%;
            margin-top: 8px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0.2;
        }

        .exclude-icon {
            opacity: 0.2;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:not(.disabled):hover {
                opacity: 1;
                color: $red-soft;
            }

            &.excluded {
                opacity: 1;
                color: $red;
            }
        }

        .jira-icon {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            box-sizing: border-box;
        }
    }

    &.excluded {
        .task-row {
            opacity: 0.5;
            position: relative;

            &::after {
                display: block;
                width: calc(100% - 56px);
                height: 1px;
                background: $black;
                content: '';
                position: absolute;
                top: 16px;
                left: 20px;
                opacity: 0.6;
            }
        }
    }

    .subtasks-container {
        border-radius: 4px;
        margin-top: -36px;
        min-height: 36px;

        .subtasks-dropzone {
            display: block;
            position: relative;
            min-height: 36px;

            &:empty {
                pointer-events: none;
            }
        }
    }
}

.row {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

::v-deep .input-value {
    width: 100%;

    &.read-only,
    &.read-only input,
    input:read-only {
        background: transparent;
        color: #a0a0a0;
        pointer-events: none;
        border: none;
    }

    input {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center;
    }

    &.bold {
        input {
            font-weight: bold;
        }
    }
}

.el-input__inner {
    height: 32px;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-family: Montserrat, sans-serif;

    .small {
        font-size: 11px;
    }

    &.bold {
        font-weight: bold;
    }

    &.cost {
        width: calc(100% - 36px);
    }

    &.right {
        text-align: right;
    }

    &.read-only {
        pointer-events: none;
        background: none;
        border: none;
        color: $black;
    }
}

.options-col {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .el-button--small {
        padding: 9px 10px;
        margin-left: 3px;
    }
}

.description {
    display: flex;
    align-items: center;

    .taskGrip {
        width: 20px;
        height: 23px;
        text-align: center;
        opacity: 0.1;
        user-select: none;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            background: #0f263b;
            width: 6px;
            height: 15px;
            top: 4px;
            left: 6.5px;
            border-radius: 2px;
        }
    }
}

.itemSortable .taskGrip {
    cursor: grab;
    opacity: 0.4;

    &:after {
        content: none;
    }
}

.ghost {
    opacity: 0.5;
}

.dragging {
    background: $grey;
}
</style>
