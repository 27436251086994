var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "document-row",
      class: {
        invalid: !_vm.doc.isExternal && !_vm.doc.valid,
        editable: !_vm.doc.isExternal,
      },
      on: { click: () => _vm.handleEditDocument(_vm.doc) },
    },
    [
      _c("span", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.doc.name) + " "),
      ]),
      _vm.doc.isExternal
        ? _c("el-button", {
            attrs: { icon: "el-icon-link", size: "mini" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return (() => _vm.handleExternalLink(_vm.doc)).apply(
                  null,
                  arguments
                )
              },
            },
          })
        : _c("el-button", {
            attrs: {
              size: "mini",
              icon: "el-icon-download",
              disabled: !_vm.doc.valid,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return (() =>
                  _vm.doc.valid ? _vm.generateDocumentPDF(_vm.doc) : 0).apply(
                  null,
                  arguments
                )
              },
            },
          }),
      _vm.allow_delete
        ? _c(
            "el-popconfirm",
            {
              directives: [
                { name: "only-super-admin", rawName: "v-only-super-admin" },
              ],
              staticClass: "document-delete-confirm",
              attrs: { title: "Delete this document?" },
              on: {
                confirm: () => _vm.handleDeleteDocument(_vm.doc),
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "reference",
                  type: "danger",
                  size: "mini",
                  plain: "",
                  icon: "el-icon-delete",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                slot: "reference",
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }